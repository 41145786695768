<template>
  <div>
    <v-header></v-header>
    <div class="container">
      <template v-for="item in questions" v-if="item.title && item.content">
        <span class="title">{{ item.title }}</span>
        <template v-if="item.lines">
          <div v-for="line in item.lines" v-html="line"></div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {questionV2Api} from "../../api/v2/questionV2Api";

export default {
  name: "about",
  data() {
    return {
      questions: []
    }
  },

  async created() {
    const res = await questionV2Api.findByClassify('方案上传规范')
    for (const q of res) {
      if (q.content && q.content.length) {
        q.lines = q.content.split('&&');
      }
    }
    this.questions = res.filter(q => q.pid === '0c52e9b2-59c5-4b83-9f20-e31e08a8228d')

  }

}
</script>

<style scoped lang="less">
.container {
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  padding: var(--header-height) 28px 24px;

  .title {
    font-weight: bold;
    padding: 10px 0;
  }

  .content {
    p, span {
      letter-spacing: 4px;
      color: #999 !important;
    }
  }
}
</style>
